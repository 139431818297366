<template>
    <section id="TraiteHarmonyMobile" class="page-traite">
        <hooper>
            <slide class="slider-item">
                <div class="opt-ctn-1 ctn-direction">
                    <div class="opt-ctn-bloc">
                        <h1 style="display: none;">Rhapsody</h1>
                        <h2 class="title-h2 text-focus-in">{{$t('titleTraiteRhapsody')}}</h2>
                        <p class="txt-turquoise">{{$t('dataTraite')['0']}}</p>
                        <p>{{$t('dataTraite')['1']}} <strong>{{$t('dataTraite')['2']}}</strong> {{$t('dataTraite')['3']}}</p>
                        <p>
                            <strong>{{$t('dataTraite')['4']}}</strong> {{$t('dataTraite')['5']}}
                        </p>
                    </div>
                </div>
            </slide>
            <slide class="slider-item">
                <div class="opt-ctn-2 txt-turquoise ctn-direction">
                    <div class="opt-ctn-bloc">
                        <div>{{$t('dataTraite')['6']}}</div>
                        <div>{{$t('dataTraite')['7']}}</div>
                    </div>
                </div>
            </slide>

            <slide class="d-mobile ctn-direction">
                <div class="d-mobile-img">
                    <figure v-for="index in logosMobile" :key="index.id"><img :src='index.src' :alt="index.alt"></figure>
                </div>
            </slide>
        </hooper>
    </section>
</template>

<script>
    import { Hooper, Slide } from 'hooper';
    import 'hooper/dist/hooper.css';

    export default {
        name: 'TraiteHarmonyMobile',
        components: {
            Hooper,
            Slide
        },
        metaInfo() {
            return { 
                title: "Rhapsody - Traité de Rhapsody",
                meta: [
                    { name: 'description', content:  'Le savoir-faire est au coeur du projet qui se veut engagé et durable. 7 certifications et labels qui garantissent un niveau de performance exceptionnel'},
                    { property: 'og:title', content: "Rhapsody - Traité de Rhapsody"},
                    { property: 'og:site_name', content: 'Rhapsody'},
                    { property: 'og:type', content: 'website'},    
                ]
            }
        },
        data:()=>({
            logosMobile:[
                {id:1, src: "/images/traite_rhapsody/wired.svg", ref:'wired', alt:'logo-wired'},
                {id:2, src: "/images/traite_rhapsody/breeam.svg", ref:'breeam', alt:'logo-breeam'},
                {id:3, src: "/images/traite_rhapsody/bbc.svg", ref:'bbc', alt:'logo-bbcEffinergie'},
                {id:4, src: "/images/traite_rhapsody/biodiuerCity.svg", ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {id:5, src: "/images/traite_rhapsody/well.svg", ref:'well', alt:'logo-well'},
                {id:6, src: "/images/traite_rhapsody/hqe.svg", ref:'hqe', alt:'logo-hqe'},
                {id:7, src: "/images/traite_rhapsody/ec.svg", ref:'ec', alt:'logo-ec'},
            ]
        }),
    };
</script>